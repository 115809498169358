<template>
  <div
    class="bg-white px-8 quotation-detail-page"
    style="height: calc(100vh - 50px)"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="6" class="d-flex align-center justify-start">
            <v-chip
              label
              outlined
              color="blue darken-4"
              class="font-weight-600"
              >{{ dbQuotation.barcode }}</v-chip
            >
            <v-chip
              label
              :color="dbQuotation?.status_relation?.color"
              :text-color="dbQuotation?.status_relation?.textcolor"
              class="ml-2 text-uppercase font-weight-600"
              >{{ dbQuotation?.status_relation?.text }}</v-chip
            >

            <v-chip
              v-if="dbQuotation?.is_renewed == 1"
              label
              color="blue darken"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              v-on:click="goToRental(dbQuotation?.rental_renewed?.id)"
            >
              Renew to - {{ dbQuotation?.rental_renewed?.barcode }}</v-chip
            >

            <v-chip
              v-if="dbQuotation?.check_sub_rent == 1"
              color="cyan"
              label
              text-color="white"
              class="mr-2 ml-2 green"
            >
              Sub Rent
            </v-chip>

            <!-- <v-chip
              v-if="dbQuotation?.invoice_converted == 1"
              label
              color="green"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Invoice</v-chip
            >
            <v-chip
              v-if="dbQuotation?.visit_converted == 1"
              label
              color="orange"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            > -->
          </v-col>
          <v-col md="6" class="d-flex align-center justify-end">
            <v-btn
              v-if="dbQuotation?.status != 2 && dbQuotation?.status != 4"
              v-on:click="updateMoreAction('edit')"
              depressed
              color="cyan white--text"
              class="custom-bold-button"
              ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
            >

            <template
              v-if="
                dbQuotation?.more_actions && dbQuotation?.more_actions?.length
              "
            >
              <template v-if="dbQuotation?.more_actions.length >= 0">
                <v-menu
                  v-if="
                    dbQuotation?.more_actions &&
                    dbQuotation?.more_actions.length
                  "
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="cyan white--text"
                      class="custom-bold-button mx-2"
                      >More...
                      <v-icon small right>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <template
                      v-for="(more, index) in dbQuotation?.more_actions"
                    >
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                        :disabled="more.disabled"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  v-for="(more, index) in dbQuotation?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template>
            </template>

            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              v-on:click="routeToCustomer()"
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
              >{{ dbQuotation?.customer_relation?.display_name }}</span
            >
          </v-col>
          <v-col md="6">
            <label class="my-0 p-0" style="font-size: 16px"
              >Billing Location</label
            >
            <template v-if="dbQuotation?.billing_relation?.id">
              <table width="100%">
                <tr
                  v-if="detailAll?.sale_info?.billing_relation?.property_name"
                >
                  <td class="p-0">
                    {{ detailAll?.sale_info?.billing_relation?.property_name }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.billing_relation?.street_1">
                  <td class="p-0">
                    {{ dbQuotation?.billing_relation?.street_1 }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.billing_relation?.street_2">
                  <td class="p-0">
                    {{ dbQuotation?.billing_relation?.street_2 }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.billing_relation?.unit_no">
                  <td class="p-0">
                    {{ dbQuotation?.billing_relation?.unit_no }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.billing_relation?.zip_code">
                  <td class="p-0">
                    {{ dbQuotation?.billing_relation?.zip_code }}
                  </td>
                </tr>
              </table>
            </template>
            <label class="my-0 p-0 mt-3" style="font-size: 16px"
              >Contact Details</label
            >
            <template v-if="dbQuotation?.billing_contact_person_relation?.id">
              <table width="100%">
                <tr
                  v-if="
                    dbQuotation?.billing_contact_person_relation?.display_name
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.billing_contact_person_relation?.display_name
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    dbQuotation?.billing_contact_person_relation?.primary_phone
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.billing_contact_person_relation
                        ?.primary_phone
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    dbQuotation?.billing_contact_person_relation?.primary_email
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.billing_contact_person_relation
                        ?.primary_email
                    }}
                  </td>
                </tr>
              </table>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0 p-0" style="font-size: 16px"
              >Site Location</label
            >
            <template v-if="dbQuotation?.property_relation?.id">
              <table width="100%">
                <tr
                  v-if="detailAll?.sale_info?.property_relation?.property_name"
                >
                  <td class="p-0">
                    {{ detailAll?.sale_info?.property_relation?.property_name }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.property_relation?.street_1">
                  <td class="p-0">
                    {{ dbQuotation?.property_relation?.street_1 }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.property_relation?.street_2">
                  <td class="p-0">
                    {{ dbQuotation?.property_relation?.street_2 }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.property_relation?.unit_no">
                  <td class="p-0">
                    {{ dbQuotation?.property_relation?.unit_no }}
                  </td>
                </tr>
                <tr v-if="dbQuotation?.property_relation?.zip_code">
                  <td class="p-0">
                    {{ dbQuotation?.property_relation?.zip_code }}
                  </td>
                </tr>
              </table>
            </template>
            <label class="my-0 p-0 mt-3" style="font-size: 16px"
              >Contact Details</label
            >
            <template v-if="dbQuotation?.property_contact_person_relation?.id">
              <table width="100%">
                <tr
                  v-if="
                    dbQuotation?.property_contact_person_relation?.display_name
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.property_contact_person_relation
                        ?.display_name
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    dbQuotation?.property_contact_person_relation?.primary_phone
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.property_contact_person_relation
                        ?.primary_phone
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    dbQuotation?.property_contact_person_relation?.primary_email
                  "
                >
                  <td class="p-0">
                    {{
                      dbQuotation?.property_contact_person_relation
                        ?.primary_email
                    }}
                  </td>
                </tr>
              </table>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        md="7"
        class="gray-background"
        style="border-left: 1px solid lightgray !important"
      >
        <v-row>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td
                  colspan="2"
                  class="color-custom-blue font-weight-700 font-size-19"
                >
                  {{ dbQuotation?.title }}
                </td>
              </tr>
              <tr>
                <td class="" width="50%">Start Date</td>
                <td class="">
                  <template v-if="dbQuotation?.start_date">
                    {{ formatDate(dbQuotation?.start_date) }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no start date</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="" width="50%">Cease Date</td>
                <td class="">
                  <template v-if="dbQuotation?.cease_date">
                    {{ formatDate(dbQuotation?.cease_date) }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no cease date</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="" width="50%">Billed (Invoice)</td>
                <td class="">
                  <template v-if="dbQuotation?.billed_invoice">
                    {{ dbQuotation?.billed_invoice }}
                  </template>
                  <template v-else
                    ><span class="text-muted">
                      no billed (invoice)
                    </span></template
                  >
                </td>
              </tr>
              <tr>
                <td class="" width="50%">Billed (Credit Note)</td>
                <td class="">
                  <template v-if="dbQuotation?.billed_credit_note">
                    {{ dbQuotation?.billed_credit_note }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no billed credit note</span>
                  </template>
                </td>
              </tr>

              <tr>
                <td class="" width="50%">Purchase Order No.</td>
                <td class="">
                  <template v-if="dbQuotation?.purchase_order_no">
                    {{ dbQuotation?.purchase_order_no }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no purchase order no.</span>
                  </template>
                </td>
              </tr>

              <!-- <tr>
                <td class="" width="50%">Customer ref</td>
                <td class="">{{ dbQuotation?.customer_ref }}</td>
              </tr>
              <tr>
                <td class="" width="50%">Your ref</td>
                <td class="">{{ dbQuotation?.your_ref }}</td>
              </tr> -->
              <tr>
                <td class="" width="50%">Prepared by</td>
                <td class="">
                  <template
                    v-if="dbQuotation?.prepared_by_person?.display_name"
                  >
                    {{ dbQuotation?.prepared_by_person?.display_name }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no prepared by</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="font-weight-600" width="50%">Total Amount</td>
                <td class="font-weight-600">
                  {{ formatMoney(dbQuotation?.total) }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="6" style="border-left: 1px solid lightgray !important">
            <table width="100%" v-if="dbQuotation.check_sub_rent == 1">
              <tr>
                <td
                  colspan="2"
                  class="color-custom-blue font-weight-700 font-size-19"
                >
                  SUB-RENT INFO
                </td>
              </tr>
              <tr>
                <td class="pb-0 pt-1" width="50%">Customer Name</td>
                <td class="pb-0 pt-1">
                  <template v-if="dbQuotation?.sub_rentals?.customer_name">
                    {{ dbQuotation?.sub_rentals?.customer_name }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no customer name</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="pb-0 pt-1" width="50%">Invoice No.</td>
                <td class="pb-0 pt-1">
                  <template v-if="dbQuotation?.sub_rentals?.invoice_no">
                    {{ dbQuotation?.sub_rentals?.invoice_no }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no invoice no.</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="pb-0 pt-1" width="50%">Start Date</td>
                <td class="pb-0 pt-1">
                  <template v-if="dbQuotation?.sub_rentals?.start_date">
                    {{ formatDate(dbQuotation?.sub_rentals?.start_date) }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no start date</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="pb-0 pt-1" width="50%">End Date</td>
                <td class="pb-0 pt-1">
                  <template v-if="dbQuotation?.sub_rentals?.off_hire">
                    {{ formatDate(dbQuotation?.sub_rentals?.off_hire) }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> no end date</span>
                  </template>
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  class="color-custom-blue font-weight-700 font-size-19"
                >
                  Current Location
                  <v-chip
                    color="cyan"
                    small
                    label
                    text-color="white"
                    class="mr-2 green"
                  >
                    Sub Rent
                  </v-chip>
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.site_location">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.site_location }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.address_location_1">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.address_location_1 }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.address_location_2">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.address_location_2 }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.unit_no">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.unit_no }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.postal_code">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.postal_code }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.sub_rentals?.country">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.sub_rentals?.country }}
                </td>
              </tr>
            </table>

            <table width="100%" v-else>
              <tr>
                <td
                  colspan="2"
                  class="color-custom-blue font-weight-700 font-size-19"
                >
                  Current Location
                </td>
              </tr>
              <tr v-if="detailAll?.property_relation?.property_name">
                <td class="pb-0 pt-1">
                  {{ detailAll?.property_relation?.property_name }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.property_relation?.street_1">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.property_relation?.street_1 }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.property_relation?.street_2">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.property_relation?.street_2 }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.property_relation?.unit_no">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.property_relation?.unit_no }}
                </td>
              </tr>
              <tr v-if="dbQuotation?.property_relation?.zip_code">
                <td class="pb-0 pt-1">
                  {{ dbQuotation?.property_relation?.zip_code }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="quotationTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Equipments
          </v-tab>

          <v-tab
            class="font-size-14 font-weight-600 px-8"
            href="#invoice"
            v-if="false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
            </span>
            Invoices
          </v-tab>
          <v-tab
            class="font-size-14 font-weight-600 px-8"
            href="#visit"
            v-if="false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
            </span>
            Visits
          </v-tab>

          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>

          <v-tab class="font-size-14 font-weight-600 px-8" href="#attachment">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
            </span>
            Attachment
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="quotationTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-row>
              <v-col cols="12" class="pb-0 pl-4">
                <div class="border mt-3">
                  <div
                    class="card--header bg-header-card d-flex align-items-center border-bottom pa-2"
                  >
                    <div class="font-level-3-bold"><b>Description</b></div>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="card--body d-flex align-items-center pa-2">
                    <template v-if="dbQuotation && dbQuotation?.description">
                      {{ dbQuotation?.description }}
                    </template>
                    <template v-else> <em>no description</em></template>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="6" class="pb-0 pl-4">
                <div class="border mt-3">
                  <div
                    class="card--header bg-header-card d-flex align-items-center border-bottom pa-2"
                  >
                    <div class="font-level-3-bold">
                      <b>Terms & Conditions</b>
                    </div>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="card--body d-flex align-items-center pa-2">
                    <template v-if="dbQuotation && dbQuotation?.admin_note">
                      <div class="w-100" v-html="dbQuotation?.admin_note"></div>
                    </template>
                    <template v-else> <em>no admin note</em></template>
                  </div>
                </div>
              </v-col>

              <v-col cols="6" class="pb-0 pl-4">
                <div class="border mt-3">
                  <div
                    class="card--header bg-header-card d-flex align-items-center border-bottom pa-2"
                  >
                    <div class="font-level-3-bold"><b>Remark</b></div>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="card--body d-flex align-items-center pa-2">
                    <template v-if="dbQuotation && dbQuotation?.remark">
                      <div class="w-100" v-html="dbQuotation?.remark"></div>
                    </template>
                    <template v-else> <em>no remark</em></template>
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- <v-expansion-panels mandatory accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      DESCRIPTION
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">{{ dbQuotation?.description }}</div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      Terms Conditions & ATTACHMENTS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6" class="custom-border-right">
                      <label class="font-size-16 font-weight-600">Terms & Conditions</label>
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.admin_note"
                          v-html="dbQuotation?.admin_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No Terms & Conditions
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" class="custom-border-left">
                      <label class="font-size-16 font-weight-600"
                        >Remark
                        
                        </label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.remark"
                          v-html="dbQuotation?.remark"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No remark
                        </div>
                      </div>
                    </v-col>
                    
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels> -->
          </v-tab-item>

          <!-- Line item list  -->
          <v-tab-item value="line-item">
            <line-item-view :id="dbQuotation?.id" type="rental" />
          </v-tab-item>

          <v-tab-item value="invoice">
            <internal-invoice type="quotation" :type-id="dbQuotation?.id" />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbQuotation?.customer"
              :property-id="dbQuotation?.property"
              :quotation-id="dbQuotation?.id"
              detailType="tickets"
              visitType="all"
              :visit-status="0"
              detailRoute="visit.detail"
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail type="rental" :type_id="dbQuotation?.id" />
          </v-tab-item>
          <v-tab-item value="attachment">
            <AdditionalDocuments
              :type-id="dbQuotation.id"
              class="mx-4"
              type-text="Rental Documents"
              :type="58"
              :extensive="false"
            ></AdditionalDocuments>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-quotation"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p class="font-weight-600 font-size-19">
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <v-layout
            class="my-4"
            v-if="
              confirmType == 'mark_as_approved' ||
              confirmType == 'mark_as_rejected' ||
              confirmType == 'mark_as_accepted' ||
              confirmType == 'mark_as_cancelled'
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16 required"
                >Remark</label
              >
              <v-textarea
                v-model.trim="actionData.remark"
                auto-grow
                dense
                filled
                color="cyan"
                label="Remark"
                solo
                flat
                row-height="25"
                :rules="[validateRules.required(actionData.remark, 'Remark')]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              ></v-textarea>
              <v-col md="12" class="text-right"
                >{{
                  actionData.remark ? actionData.remark.length : 0
                }}/200</v-col
              >
            </v-flex>
          </v-layout>

          <v-layout
            class="mt-4"
            v-if="
              confirmType == 'mark_as_approved' ||
              confirmType == 'mark_as_rejected' ||
              confirmType == 'mark_as_cancelled'
            "
          >
            <v-flex md12>
              <label class="font-weight-500 font-size-16"
                ><span style="color: rgba(0, 0, 0, 0.6)"
                  >Attachments (Max 5)</span
                ><br />
                <span class="text--brown" style="color: rgba(0, 0, 0, 0.6)">
                  Allowed Extensions:
                  doc,docx,txt,text,rtf,xsl,jpeg,png,gif,zip,rar,pdf,xlsx,word,jpg
                  <br />
                  Max files: 5, Max Size: 5MB
                </span>
              </label>
              <table width="100%">
                <tbody>
                  <tr
                    v-for="(row, index) in actionData.attachments"
                    :key="index"
                  >
                    <td width="50%" class="pl-0 pr-2 py-2">
                      <v-file-input
                        prepend-inner-icon="mdi-attachment mdi-rotate-90"
                        placeholder="Click here to select file"
                        label="Click here to select file"
                        prepend-icon=""
                        color="cyan"
                        solo
                        flat
                        v-model="row.accepted_file"
                        dense
                        v-on:change="updateFileName(index)"
                        hide-details
                      ></v-file-input>
                    </td>
                    <td width="50%" class="pr-0 pl-2 py-2">
                      <v-text-field
                        placeholder="File Name"
                        label="File Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        v-model="row.file_name"
                        hide-details
                        class="has-delete-outer-icon"
                        append-outer-icon="mdi-delete"
                        v-on:click:append-outer="removeRow(index)"
                        :suffix="getFileExtension(row.file_type)"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="actionData?.attachments?.length < 5">
                    <td colspan="2" class="pl-0">
                      <v-btn
                        color="cyan white--text"
                        small
                        v-on:click="addAttachmentRow()"
                        class="custom-bold-button"
                        >Add More...</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      title="Send as Email"
      type="quotation"
      v-on:close="mailDialog = false"
      :type-id="quotationId"
      v-on:success="getQuotation()"
    ></send-mail>
  </div>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
//import LineItemViewV2 from "@/view/pages/partials/Line-Item-View-V2.vue";
//import LineItemViewV2 from "@/view/pages/partials/Line-Item-V3.vue"; //old
import LineItemViewV2 from "@/view/pages/partials/Line-Item-Listing-Detail.vue";

import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";

/*import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      quotationTab: "line-item",
      dbQuotation: {},
      quotationId: 0,
      pageLoaded: false,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      confirmType: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.actionData.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.actionData.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (this.actionData.remark && this.actionData.remark.length > 199) {
        e.preventDefault();
      }
    },

    confirmSubmit() {
      switch (this.confirmType) {
        // case "mark_as_closed":
        //   this.updateStatus("mark_as_closed");
        //   break;
        // case "mark_as_cancelled":
        //   this.updateStatus("mark_as_cancelled");
        //   break;
        // case "mark_as_pending":
        //   this.updateStatus("mark_as_pending");
        //   break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        // case "mark_as_accepted":
        //   this.updateStatus("mark_as_accepted");
        //   break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData?.attachments?.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      // if(!this.actionData.remark) {
      //   return false;
      // }

      this.confirmLoading = true;
      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "rental/" + this.quotationId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getQuotation();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData?.attachments?.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData?.attachments?.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { quotation: this.dbQuotation?.id },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: { quotation: this.dbQuotation?.id },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { duplicate: this.dbQuotation?.id },
        })
      );
    },
    editSell() {
      //sell update
      this.$router.push(
        this.getDefaultRoute("rental.update", {
          query: { edit: this.dbQuotation?.id },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { revise: this.dbQuotation?.id },
        })
      );
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },

    goToRental(id = 0) {
      //console.log(id);
      this.$router.push(
        this.getDefaultRoute("rental.detail", {
          params: { id },
        })
      );
      this.getQuotation(id);
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editSell();
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "renew":
          this.$router.push(
            this.getDefaultRoute("rental.update", {
              query: {
                edit: this.dbQuotation?.id,
                duplicate: this.dbQuotation?.id,
              },
            })
          );
          break;
        case "cancel":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;

        // case "download_pdf":
        //   this.downloadPDF();
        //   break;
        // case "print":
        //   this.printPDF();
        //   break;
      }
    },
    routeToCustomer() {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { id: this.dbQuotation?.customer },
        })
      );
    },
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getQuotation(id = this.quotationId) {
      this.pageLoaded = false;
      this.$store
        .dispatch(GET, { url: "rental/" + id })
        .then(({ data }) => {
          this.dbQuotation = data;
          this.$store.dispatch(SET_VIEW_CALCULATION, {
            ctx_discount_label: this.dbQuotation?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbQuotation?.ctx_discount_value ?? 0,
            ctx_discount: this.dbQuotation?.ctx_discount ?? 0,
            ctx_discount_type: this.dbQuotation?.ctx_discount_type ?? 1,
            sub_total: this.dbQuotation?.sub_total ?? 1,
            discount_type: this.dbQuotation?.discount_type ?? 1,
            discount_value: this.dbQuotation?.discount_value ?? 0,
            discount_amount: this.dbQuotation?.discount_amount ?? 0,
            tax_active: this.dbQuotation?.tax_active ?? false,
            tax_value: this.dbQuotation?.tax_value ?? 0,
            tax_amount: this.dbQuotation?.tax_amount ?? 0,
            adjustment: this.dbQuotation?.adjustment ?? 0,
            total: this.dbQuotation?.total ?? 0,
            show_price: true,
            discount_level: this.dbQuotation?.discount_level ?? "transaction",
          });
          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Rental", route: "rental" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
    this.quotationTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getQuotation();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-visit": InternalVisit,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
    AdditionalDocuments,
  },
};
</script>
<style lang="scss" scoped>
.bg-header-card {
  background: #f0f1ff;
}
.quotation-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
